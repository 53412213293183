import React from 'react';

const Reporting = () => {
    return (
        <div>
            <h2>Reporting</h2>
            <p>Nothing to see here...</p>
        </div>
    );
};

export default Reporting;