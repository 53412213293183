import React from 'react';
import { useSelector } from 'react-redux';

const Home = () => {

    // User
    const { user } = useSelector(state => state.auth);

    return (
        <div>
            <h2>Angelytics</h2>
            <p>A HIPPA compliant analytics platform.</p>
            <p>Your api key is:</p>
            <p>{user?.apiKey || ""}</p>
        </div>
    );
};

export default Home;