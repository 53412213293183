import jwtAxios from "axios";

export const baseURL = process.env.NODE_ENV === "production" ? "https://api.angelytics.ai" : "http://localhost:5050";

const jwtAuthAxios = jwtAxios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        // 'Origin': ('https://angelytics.ai' || 'http://localhost:3000')
    }
});


jwtAuthAxios.interceptors.response.use(

    res => res,
    err => {
        console.log(" JWT ERROR == interceptors.response.use", err, err.response)
        if (err.response) {

            setAuthToken(null);
        }


        return Promise.reject(err);
    }
);

export const setAuthToken = (token) => {
    if (token) {
        jwtAuthAxios.defaults.headers.common['x-access-token'] = token;
        sessionStorage.setItem('token', token);
    } else {
        delete jwtAuthAxios.defaults.headers.common['x-access-token'];
        sessionStorage.removeItem('token');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem("token");
};

// export const config = () => {
//     const token = sessionStorage.getItem("token");
//     return {
//         headers: {
//             "Content-Type": "application/json",
//             "x-access-token": token
//         }
//     }
// }

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;