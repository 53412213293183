import { TableCell, TableRow } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import React from "react";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));

const PageViewsItem = ({ item }) => {

    return (
        <StyledTableRow key={item.title}>
            <TableCell sx={{ pl: theme => theme.spacing(3) }}>{item.title || '--'}</TableCell>


            {/* <TableCell align="right">{item.href}</TableCell> */}

            <TableCell align="right" sx={{ pr: theme => theme.spacing(3) }}>{item.totalViews}</TableCell>
            <TableCell align="right" sx={{ pr: theme => theme.spacing(3) }}>{item.totalUsers}</TableCell>
        </StyledTableRow>

    );
};
/* Todo item prop define */
export default PageViewsItem;
