import React, { useEffect, useState } from 'react';
import ProjectItem from "../../list-views/ProjectsList/ProjectItem";
// import { projects } from "./data";
import { Box, Menu, MenuItem, Select, Switch, Typography } from "@mui/material";
import { getAssetPath } from 'app/utils/appHelpers';
import { ASSET_AVATARS, ASSET_LOGOS } from 'app/utils/constants/paths';
import { getEvents } from 'app/redux/actions/event';
import { useDispatch, useSelector } from 'react-redux';
import LastThirtyMinutes from './elements/LastMonth';
// import aggregateService from 'app/redux/actions/aggregate';
import moment from 'moment';
import { getFeed, getFeed_v2, getFeed_v3 } from 'app/redux/actions/endpoints/realTimeData';
import PageViews from './elements/PageViews/PageViews';
import EventDetails from './elements/EventDetails';
import TimezoneLocation from './elements/TimezoneLocation';
import ListDisplay from '../../../shared/widgets/ListDisplay'

const Current = () => {
    // Redux
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { domain } = useSelector(state => state.system_preferences)

    // State
    const [eventsData, setEventsData] = useState([]);
    const [usersPerMinute, setUsersPerMinute] = useState([]);
    const [options, setOptions] = useState({})
    const [limit, setLimit] = useState(10)
    const [includeErrors, setIncludeErrors] = useState(false)
    const [pageViews, setPageViews] = useState([])
    const [journeys, setJourneys] = useState([])
    const [userJourneyInsights, setUserJourneyInsights] = useState([])
    const [eventDetails, setEventDetails] = useState([])
    const [feed, setFeed] = useState(null)

    const [angel_ai_insight, setAngelAiInsight] = useState("")
    const [ga_ai_insight, setGaAiInsight] = useState("")

    // Get feed on load
    useEffect(() => {
        console.log("Getting feed")
        console.log("User", user)



        if (user?.apiKey) {
            console.log("Getting feed - user has api key")
            handleGetFeed()
        }
    }, [user])

    const handleGetFeed = async () => {
        let feedResponse = await dispatch(getFeed_v3())

        console.log("FEED RESPONSE :: ", feedResponse)

        setAngelAiInsight(feedResponse.feed.AngelEvents_insight)
        setGaAiInsight(feedResponse.feed.GAEvents_insight)

    }


    // Initial fetch for real time data
    // useEffect(() => {
    //     if (user?.apiKey) {
    //         getRealTimeData("Initial");
    //     }
    // }, [user]);

    // Get events data every interval
    // const intervalLength = 10000
    // useEffect(() => {
    //     let intervalId = null;

    //     const fetchData = async () => {
    //         await getRealTimeData("Interval");
    //     };

    //     if (user?.apiKey) {
    //         intervalId = setInterval(fetchData, intervalLength);
    //     }

    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [user, options, limit, includeErrors]);

    // Update options with domain
    // useEffect(() => {
    //     if (domain) {
    //         setOptions({ ...options, domain: domain })
    //         getRealTimeData(`Domain update ${domain}`);
    //     }
    // }, [domain])

    // Re fetch on update. 
    // useEffect(() => {

    //     getRealTimeData("Options update");

    // }, [limit, includeErrors]) // add updates here



    const getRealTimeData = async (from) => {
        // console.log("Getting real time data from", from)

        let feed = await dispatch(getFeed({
            limit,
            includeErrors,
            options: {
                ...options,
                ...(domain ? domain : null),
                from
            }
        }));

        console.log("got feed", feed)

        setFeed(feed)

        // let { events, usersPerMinute, pageViews, journeys, userJourneyInsights, eventDetails } = await dispatch(getFeed({
        //     limit,
        //     includeErrors,
        //     options: {
        //         ...options,
        //         ...(domain ? domain : null),
        //         from
        //     }
        // }));

        // setFeed({ events, usersPerMinute, pageViews, journeys, userJourneyInsights, eventDetails })

        // // Page Views
        // setPageViews((state, prev) => {
        //     if (JSON.stringify(pageViews) !== JSON.stringify(state)) return pageViews
        //     // if (pageViews !== state) return pageViews
        //     return state
        // })

        // // Raw Events - this will go away
        // setEventsData((state, prev) => {
        //     if (JSON.stringify(events) !== JSON.stringify(state)) return events
        //     // if (events !== state) return events
        //     return state
        // })

        // // Journeys
        // setJourneys((state, prev) => {
        //     if (JSON.stringify(journeys) !== JSON.stringify(state)) return journeys
        //     return state
        // })

        // // Users per minute
        // setUsersPerMinute((state, prev) => {
        //     if (JSON.stringify(usersPerMinute) !== JSON.stringify(state)) return usersPerMinute
        //     // if (usersPerMinute !== state) return usersPerMinute
        //     return state
        // })

        // // User Journey Insights
        // setUserJourneyInsights((state, prev) => {
        //     if (JSON.stringify(userJourneyInsights) !== JSON.stringify(state)) return userJourneyInsights
        //     // if (userJourneyInsights !== state) return userJourneyInsights
        //     return state
        // })

        // // Event Details
        // setEventDetails((state, prev) => {
        //     if (JSON.stringify(eventDetails) !== JSON.stringify(state)) return eventDetails
        //     // if (eventDetails !== state) return eventDetails
        //     return state
        // })




        // let feed_v2Response = await dispatch(getFeed_v2({
        //     limit,
        //     includeErrors,
        //     options: {
        //         ...options,
        //         ...(domain ? domain : null),
        //         from
        //     }
        // }));


    }

    console.log("Feed", feed)
    return (
        <div>
            <Typography variant={'h2'} mb={3}>Real time</Typography>
            <Typography variant={'h3'} mb={3}>Angel: {angel_ai_insight}</Typography>
            <Typography variant={'h3'} mb={3}>GA: {ga_ai_insight}</Typography>

            {/* Real time graph of sessions in the last 30 minutes */}
            <LastThirtyMinutes data={[]} userJourneyInsights={[]} eventDetails={[]} />

            {/* Page Views */}
            {/* <PageViews pageViews={feed.pageViews} /> */}

            {/* Event Details */}
            {/* <EventDetails eventDetails={feed.eventDetails} /> */}

            {/* Referrer */}
            {/* <ListDisplay
                data={feed.referrers}
                title="Referrers"
                subheader="Identifies the address of the web page, from which the resource has been requested"
            /> */}

            {/* Timezone / Location */}
            {/* <TimezoneLocation locations={feed.locations} /> */}


            {/* Raw Data */}
            {/* <Typography variant={'h3'} mb={3}>Raw data</Typography> */}
            {/* <Box style={{
                display: 'flex',
            }}>
                <div style={{
                    margin: '10px 20px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{ marginRight: "8px" }}>The last {feed?.eventsData?.length} events</p>
                    <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </div>

                <div style={{
                    margin: '10px 20px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{ marginRight: "8px" }}>Show errors</p>
                    <Switch
                        checked={includeErrors}
                        onChange={e => setIncludeErrors(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>

            </Box> */}


            {/* {
                feed.events.map((project, key) => (
                    <ProjectItem item={project} data={feed.events} />
                ))
            } */}



        </div>
    );
};

export default Current;