import {matchRoutes, useLocation} from "react-router-dom";
import {buildRoutes} from "@jumbo/utils";
import { useEffect, useState } from "react";

const useRoutePathMatch = (routes, searchPath) => {
    const location =  useLocation();

    const [matchedRoutes, setMatchedRoutes] = useState(false);

    const checkRouteMatch = async () => {
        if(Array.isArray(routes) && routes.length > 0 && (searchPath || location?.pathname)) {
            const generatedRoutes = await buildRoutes(routes);
            const matchedRoutes = matchRoutes(generatedRoutes, location.pathname);
            if(matchedRoutes && matchedRoutes.length > 0) {
                setMatchedRoutes(true)
            } else {
                setMatchedRoutes(false);
            }
        }
    }

    useEffect(() => {
        checkRouteMatch()
    },[location]);

    return matchedRoutes;
};

export default useRoutePathMatch;