const initialState = {
    domain: null,
}

const systemPreferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DOMAIN":
            return {
                ...state,
                domain: action.payload,
            }

        default:
            return state
    }
}

export default systemPreferencesReducer