import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const { data } = await jwtAuthAxios.get("/auth/current");
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const { data } = await jwtAuthAxios.post('/auth/user-login', loginCreds);
    return data;
};

authServices.signup = async (user) => {
    const { data } = await jwtAuthAxios.post('/auth/new-user', user);
    return data;
};

authServices.sendVerifyEmail = async (user) => {
    const { data } = await jwtAuthAxios.post('/auth/sendVerifyEmail', user);
    return data;
};

authServices.verifyEmailAdress = async (user) => {
    const { data } = await jwtAuthAxios.post('/auth/verifyEmail', user);
    return data;
};

authServices.sendPasswordResetEmail = async (user) => {
    const { data } = await jwtAuthAxios.post('/auth/sendPasswordResetEmail', user);
    return data;
};

authServices.submitPasswordReset = async (user) => {
    const { data } = await jwtAuthAxios.post('/auth/resetPassword', user);
    return data;
};

export default authServices;