import React from 'react';
import { Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import { TrendingUp } from "@mui/icons-material";
import ChartLastMonthSales from "./ChartLastMonthSales";

const LastMonth = ({ data, userJourneyInsights, eventDetails }) => {
    const { t } = useTranslation();

    const getTotalSessions = () => {
        let total = 0;
        data.forEach((item) => {
            total += item.count;
        });

        return total;
    }

    const getTotalEventCount = () => {
        let total = 0;
        eventDetails.forEach((item) => {
            total += item.count;
        });
        return total
    }

    // console.log({ data, userJourneyInsights, eventDetails })
    return (
        <JumboCardQuick
            style={{
                margin: '1rem 0rem',
            }}
            noWrapper
            title={<Typography variant={"h2"} color={"common.white"}>Last 30 minutes</Typography>}
            sx={{ color: "common.white" }}
            bgColor={"#6f42c1"}
            headerSx={{ pb: 0 }}
        >
            <div style={{
                padding: '0rem 1.5rem',
                display: 'flex',
            }}>

                <div style={{
                    marginRight: '1.25rem',
                }}>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"h4"} color={"common.white"}>{getTotalSessions() || '--'}</Typography>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"p"} color={"common.white"}>Total Sessions</Typography>
                </div>

                <div style={{
                    marginRight: '1.25rem',
                }}>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"h4"} color={"common.white"}>{userJourneyInsights.averageSessionTimeInMinutesWritten || '--'}</Typography>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"p"} color={"common.white"}>Average Session Time</Typography>
                </div>

                <div style={{
                    marginRight: '1.25rem',
                }}>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"h4"} color={"common.white"}>{getTotalEventCount() || '--'}</Typography>
                    <Typography style={{ margin: '0rem 0rem', padding: '0rem 0rem' }} variant={"p"} color={"common.white"}>Event Count</Typography>
                </div>


            </div>
            <ChartLastMonthSales data={data} />
        </JumboCardQuick>
    );
};

export default LastMonth;
