import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from "./contactsApp"
import authReducer from './auth';
import eventReducer from './event';
import systemPreferencesReducer from './systemPreferences';

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        auth: authReducer,
        event: eventReducer,
        system_preferences: systemPreferencesReducer,

    });
};

export default exportReducers;

