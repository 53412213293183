import { config } from "../../../app/config/main";

export const storeToken = (token) => {
    sessionStorage.setItem('token', token);
    if (!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        config.authSetting.axiosObject.defaults.headers.common['x-access-token'] = token;
};

export const removeToken = () => {
    sessionStorage.removeItem('token');
    if (!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        delete config.authSetting.axiosObject.defaults.headers.common['x-access-token'];
};