import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import Current from "app/pages/dashboards/current/current";
import Reporting from "app/pages/dashboards/reporting";
import Login2 from "app/pages/auth-pages/login2";
import Signup2 from "app/pages/auth-pages/signup2";
import UserProfile from "app/pages/user/profile";
import VerifyEmail from "app/pages/auth-pages/verifyEmail/verifyEmail";
import resetPassword from "app/pages/auth-pages/reset-password";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/",
        element: <Page component={Home} />
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/dashboards/current",
        element: <Page component={Current} />
    },
    {
        path: "/dashboards/reporting",
        element: <Page component={Reporting} />
    },
    {
        path: "/user/profile",
        element: <Page component={UserProfile} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/user/login",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true} />
    },
    {
        path: "/user/signup",
        element: <Page component={Signup2} layout={"solo-page"} disableSmLogin={true} />
    },
    {
        path: "/user/verify-email",
        element: <Page component={VerifyEmail} layout={"solo-page"} disableSmLogin={true} />
    },
    {
        path: "/user/password-reset",
        element: <Page component={resetPassword} layout={"solo-page"} disableSmLogin={true} />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };