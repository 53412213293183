import jwtAuthAxios from 'app/services/auth/jwtAuth';

export const getFeed = (body) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {

        const response = await jwtAuthAxios.post("/real-time", body);

        // console.log(response.data.doc)
        // console.log(response.data.msg)
        return resolve(response.data)

    })
};

export const getFeed_v2 = (body) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {



        const response = await jwtAuthAxios.post("/feed", body);

        console.log("FEED RESPONSE :: ", response.data)
        return resolve(response.data)

    })
};

export const getFeed_v3 = (body) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {



        const response = await jwtAuthAxios.post("/feed3", body);

        console.log("FEED RESPONSE :: ", response.data)
        return resolve(response.data)

    })
};