import React, { useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from 'notistack';
import { ASSET_IMAGES, PUBLIC_FOLDER } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import authServices from "../../../services/auth-services";
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyEmail = () => {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [searchParams, setSearchParams] = useSearchParams();
    const [sendMailInprogress, setSendMailInprogress] = useState(false);
    const [count, setCount] = useState(10);
    const [verifyEmailInProgress, setVerifyEmailInProgress] = useState(false);
    const [accountNotFound, setAccountNotFound] = useState(false);
    const [linkExpired, setLinkExpired] = useState(false);
    const [documentTitle, setDocumentTitle] = useState('Angelytics');

    useEffect(() => {
        if(searchParams.get('email') && searchParams.get('key')) {
            setVerifyEmailInProgress(true);
        }
    },[searchParams]);

    useEffect(() => {
        if(verifyEmailInProgress) {
            verifyEmail();
        }
    },[verifyEmailInProgress]);

    useEffect(() => {
        if(sendMailInprogress) {
            setDocumentTitle('sending...')
        } else if (verifyEmailInProgress) {
            setDocumentTitle('verifying...')
        } else if (accountNotFound) {
            setDocumentTitle('not found...')
        } else if (linkExpired) {
            setDocumentTitle('expired...')
        }
    },[sendMailInprogress, verifyEmailInProgress, accountNotFound, linkExpired])

    useEffect(() => {
        document.title = documentTitle;
    },[documentTitle]);

    const navigateToSignup = () => {
        navigate('/user/signup');
    }

    const forceWaitUser = () => {
        let id = setInterval(() => {
            setCount((preState) => {
                if(preState === 0) {
                    setSendMailInprogress(false);
                    clearInterval(id);
                    return 0;
                }
                return preState-1;
            });                    
        }, 1000);
    }

    const sendVerification = () => {
        if(!searchParams.get('email')) {
            return enqueueSnackbar('No Email Address Found', {
                variant: 'error',
            });
        }
        setSendMailInprogress(true)
        setCount(10)
        authServices.sendVerifyEmail(
            {
                "_model": "User",
                "params": {
                    email: searchParams.get('email')
                }
            }
        )
            .then((data) => {

                console.log("Send verification email :: ", data)

                enqueueSnackbar(data?.msg, {
                    variant: 'success',
                });

                forceWaitUser();
            })
            .catch((err) => {

                enqueueSnackbar(err.response?.data?.msg, {
                    variant: 'error',
                });
                forceWaitUser();
                if(err.response?.status == 404) {
                    setAccountNotFound(true);
                    return;
                }

                if(err.response?.status == 400) {
                    return navigate('/user/login');
                }

            })
    };

    const verifyEmail = () => {
        authServices.verifyEmailAdress(
            {
                "_model": "User",
                "params": {
                    email: searchParams.get('email'),
                    key: searchParams.get('key'),
                }
            }
        )
            .then((data) => {

                console.log("Verified email address :: ", data)

                enqueueSnackbar(data?.msg, {
                    variant: 'success',
                });

                return navigate('/user/login')

            })
            .catch((err) => {

                enqueueSnackbar(err.response?.data?.msg, {
                    variant: 'error',
                });

                if(err.response?.status == 404) {
                    setVerifyEmailInProgress(false);
                    setAccountNotFound(true);
                    return;
                }

                if(err.response?.status == 401) {
                    setVerifyEmailInProgress(false);
                    setLinkExpired(true);
                    return;
                }
            })
    };

    return(
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card>
                <CardContent sx={{
                    flex: '0 1 300px',
                    position: 'relative',
                    background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                    backgroundSize: 'cover',
                    padding: '24px 0px',

                    '&::after': {
                        display: 'inline-block',
                        position: 'absolute',
                        content: `''`,
                        inset: 0,
                        backgroundColor: alpha('#0267a0', .65)
                    }
                }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                        }}
                    >
                        <Div sx={{
                                textAlign: 'center'
                            }}>
                                <Div sx={{
                                    height: '50px',
                                    maxHeight: '50px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    marginBottom: '10px'
                                }}>
                                    <img style={{width: '50px', height: '50px'}} src={`${PUBLIC_FOLDER}/images/icons/mail-icon.png`} alt="email"/>
                                </Div>
                                <Typography style={{letterSpacing: '5px'}} variant={"h6"} color={"inherit"} fontWeight={400} mb={1}>THANKS FOR SIGNING UP!</Typography>
                                <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Verify Your E-mail Address</Typography>
                                <Div sx={{
                                    background: '#fff',
                                    color: '#000',
                                    minHeight: '350px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '24px'
                                    }}>
                                    { (verifyEmailInProgress && !accountNotFound) &&
                                        <Div>
                                            <CircularProgress />
                                            <Typography variant={"h5"} color={"inherit"} fontWeight={400} mb={3}>Verifying your email...</Typography>
                                        </Div> 
                                    }
                                    {(!verifyEmailInProgress && !accountNotFound) &&
                                        <Div>
                                            <Typography variant={"h5"} color={"inherit"} fontWeight={400} mb={3}>Hi,</Typography>
                                            <Typography variant={"body1"} mb={5}>
                                                You're almost ready to get started.
                                                {linkExpired ? ' Your previous link has been expired. Re-send it now and verify again' : 'We have sent you an email. Please verify your email address and enjoy with Angelytics.'}
                                            </Typography>
                                            <Typography color={'#D3D3D3'} mb={1}>{searchParams.get('email')}</Typography>
                                            <LoadingButton
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 1 }}
                                                loading={sendMailInprogress}
                                                onClick={() => sendVerification()}
                                            >Re-Send Email</LoadingButton>
                                            {sendMailInprogress && <Typography color={"inherit"} >wait {count} sec</Typography>}
                                            <Typography variant={"body1"} color={"inherit"} mt={5} >Thanks,</Typography>
                                            <Typography variant={"body1"} mb={3}>
                                                The Company Team
                                            </Typography>
                                        </Div>
                                    }
                                    {(accountNotFound && !verifyEmailInProgress) && 
                                        <Div>
                                            <Typography variant={"h5"} color={"inherit"} fontWeight={400} mb={3}>Your account not found. Please signup first</Typography>
                                            <LoadingButton
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 1 }}
                                                onClick={() => navigateToSignup()}
                                            >Signup</LoadingButton>
                                        </Div>
                                    }
                                </Div>
                                {(!verifyEmailInProgress && !accountNotFound) && <Typography variant={"body1"}>
                                    Already have an account? <Link href={"/user/login"} color={"inherit"} underline={'none'}>Sign
                                        in</Link>
                                </Typography>}
                        </Div>

                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Angelytics" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
            </Card>
        </Div>
    )
};

export default VerifyEmail;
