import React, { useEffect } from 'react';
import { Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../../services/auth-services";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useDispatch } from 'react-redux';
import { setUser } from 'app/redux/actions/auth';

import { useJumboDialog } from "../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useSnackbar } from 'notistack';


const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Login2 = ({ disableSmLogin }) => {
    // REDUX
    const dispatch = useDispatch();

    // JUMBO
    const { enqueueSnackbar } = useSnackbar();

    const { variant, showDialog, hideDialog, ...restDialogProps } = useJumboDialog();



    const { setAuthToken } = useJumboAuth();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'signin'
    },[])

    const onSignIn = (email, password) => {
        authServices.signIn(
            {
                "_model": "User",
                "params": {
                    email,
                    password
                }
            }
        )
            .then((data) => {

                console.log(" SIGN IN DATA :: ", data)

                enqueueSnackbar(data.msg, {
                    variant: 'success',
                });

                setAuthToken(data?.token);
                dispatch(setUser(data.account))

                navigate("/dashboards/current");
            })
            .catch((err) => {

                enqueueSnackbar(err.response.data.msg, {
                    variant: 'error',
                });

            })
    };

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#666', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Sign In</Typography>
                            <Typography variant={"body1"} mb={2}>
                                Sign into your account using your email and password.
                            </Typography>
                            <Typography variant={"body1"} mb={2}>
                                <Link
                                    href={"/user/signup"}
                                    color={"inherit"}
                                    underline={'none'}
                                >Don't have an account yet?
                                </Link>
                            </Typography>
                            <Typography variant={"body1"}>
                                <Link
                                    href={"/user/password-reset"}
                                    color={"inherit"}
                                    underline={'none'}
                                >Forgot your password? Recover Now
                                </Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img src={`${ASSET_IMAGES}/Angelitics.png`} alt="Angelytics" width={120} />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: 'demo@example.com',
                            password: 'ABC123DEF',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                    />
                                </Div>
                                <Div sx={{ mb: 2 }}>
                                    <FormControlLabel control={<Checkbox />} label="Remember me" />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >Sign In</LoadingButton>
                                {
                                    !disableSmLogin && (
                                        <React.Fragment>
                                            <Typography variant={"body1"} mb={2}>Or sign in with</Typography>
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <IconButton sx={{
                                                    bgcolor: '#385196',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#385196',
                                                    }
                                                }} aria-label="Facebook">
                                                    <Facebook fontSize="small" />
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#00a8ff',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#00a8ff',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Twitter fontSize="small" />
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#23272b',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#23272b',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Google fontSize="small" />
                                                </IconButton>
                                            </Stack>
                                        </React.Fragment>
                                    )
                                }

                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
