import { TableCell, TableRow } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));

const PageViewsItem = ({ item }) => {

    return (
        <StyledTableRow key={item.title}>
            <TableCell sx={{ pl: theme => theme.spacing(3) }}>{item._id || '--'}</TableCell>


            {/* <TableCell align="right">{item.href}</TableCell> */}

            <TableCell align="right" sx={{ pr: theme => theme.spacing(3) }}>{item.count}</TableCell>
        </StyledTableRow>

    );
};
/* Todo item prop define */
export default PageViewsItem;
