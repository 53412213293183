import React, { useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import { Card, CardContent, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from 'notistack';
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import authServices from "../../../services/auth-services";
import { useNavigate } from 'react-router-dom';

const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
};

const validationSchema = yup.object({
    firstName: yup
        .string('Enter your first name')
        .required('First Name is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
        .matches(/\W/, getCharacterValidationError("symbol")),
    confirmPassword: yup
        .string('Confirm your password')
        .required('Confirm password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const Signup2 = () => {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        document.title = 'signup'
    },[])

    const onSignup = (data) => {
        setSubmitting(true);
        authServices.signup(
            {
                "_model": "User",
                "params": {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    password: data.password,
                    companyName: data.companyName
                }
            }
        )
            .then((data) => {

                console.log(" SIGN UP DATA :: ", data)

                enqueueSnackbar(data.msg, {
                    variant: 'success',
                });

                setSubmitting(false);
                navigate(`/user/verify-email?email=${data?.account?.email}`);

            })
            .catch((err) => {

                enqueueSnackbar(err.response.data.msg, {
                    variant: 'error',
                });

                setSubmitting(false);

            })
    };

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>

            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Sign Up</Typography>
                            <Typography variant={"body1"} mb={2}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the.
                            </Typography>
                            <Typography variant={"body1"}>
                                Already have an account? <Link href={"/user/login"} color={"inherit"} underline={'none'}>Sign
                                    in</Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Angelytics" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{}}
                        validationSchema={validationSchema}
                        onSubmit={(data) => {
                            onSignup(data);
                        }}
                    >
                        <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <JumboTextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <JumboTextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <JumboTextField
                                    fullWidth
                                    name="companyName"
                                    label="Company Name"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <JumboTextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 2 }}>
                                <JumboTextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 2 }}>
                                <JumboTextField
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                />
                            </Div>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                                loading={isSubmitting}
                            >Signup</LoadingButton>
                        </Form>
                    </Formik>
                </CardContent>
            </Card>

        </Div>
    );
};

export default Signup2;
