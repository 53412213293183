import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import authServices from 'app/services/auth-services';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';

const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
};

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
        .matches(/\W/, getCharacterValidationError("symbol")),
    confirmPassword: yup
        .string('Confirm your password')
        .required('Confirm password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const ResetPassword = () => {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [sendEmailInProgress, setSendEmailInProgress] = useState(false);
    const [count, setCount] = useState(10);
    const [newPasswordScreen, setNewPasswordScreen] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if(searchParams.get('email') && searchParams.get('key')) {
            setNewPasswordScreen(true);
        }
    },[searchParams]);

    const forceWaitUser = () => {
        let id = setInterval(() => {
            setCount((preState) => {
                if(preState === 0) {
                    setSendEmailInProgress(false);
                    clearInterval(id);
                    return 0;
                }
                return preState-1;
            });                    
        }, 1000);
    }

    const submitNewPassword = (data) => {
        setSubmitting(true);
        authServices.submitPasswordReset(
            {
                "_model": "User",
                "params": {
                    email: searchParams.get('email'),
                    password: data.password,
                    confirmPassword: data.confirmPassword,
                    key: searchParams.get('key')
                }
            }
        )
            .then((data) => {

                console.log(" Password Reset data :: ", data)

                enqueueSnackbar(data.msg, {
                    variant: 'success',
                });

                setSubmitting(false);
                navigate(`/user/login`);

            })
            .catch((err) => {

                enqueueSnackbar(err.response.data.msg, {
                    variant: 'error',
                });

                setSubmitting(false);

            })
    };

    const sendPasswordResetEmail = () => {
        if(!email || email.trim() === '') {
            return enqueueSnackbar('email not found', {
                variant: 'error',
            });
        }
        setSendEmailInProgress(true);
        setCount(10)
        authServices.sendPasswordResetEmail(
            {
                "_model": "User",
                "params": {
                    email: email
                }
            }
        )
            .then((data) => {

                console.log(" SIGN UP DATA :: ", data)

                enqueueSnackbar(data.msg, {
                    variant: 'success',
                });

                forceWaitUser();

            })
            .catch((err) => {

                enqueueSnackbar(err.response.data.msg, {
                    variant: 'error',
                });

                forceWaitUser();

            })
    };

    if(newPasswordScreen) {
        return (
            <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' }
                    }}
                >
                    <CardContent
                        sx={{
                            flex: '0 1 300px',
                            position: 'relative',
                            background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`)}) no-repeat center`,
                            backgroundSize: 'cover',
    
                            '&::after': {
                                display: 'inline-block',
                                position: 'absolute',
                                content: `''`,
                                inset: 0,
                                backgroundColor: alpha('#0267a0', .65)
                            }
                        }}
                    >
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                flex: 1,
                                flexDirection: 'column',
                                color: 'common.white',
                                position: 'relative',
                                zIndex: 1,
                                height: '100%',
                                minHeight: { md: 320 }
                            }}
                        >
                            <Div sx={{ mb: 2 }}>
                                <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Reset
                                    Password</Typography>
                                <Typography variant={"body1"} mb={1} sx={{ maxWidth: 270 }}>
                                    Enter your new password, confirm password and click submit.
                                </Typography>
                            </Div>
                            <Div sx={{ mt: 'auto' }}>
                                <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                    <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Angelytics" />
                                </Link>
                            </Div>
                        </Div>
                    </CardContent>
                    <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{email: searchParams.get('email')}}
                        validationSchema={validationSchema}
                        onSubmit={(data) => {
                            submitNewPassword(data);
                        }}
                    >
                        <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <JumboTextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    disabled={true}
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 2 }}>
                                <JumboTextField
                                    fullWidth
                                    name="password"
                                    label="new Password"
                                    type="password"
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 2 }}>
                                <JumboTextField
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                />
                            </Div>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                                loading={isSubmitting}
                            >Submit</LoadingButton>
                        </Form>
                    </Formik>
                </CardContent>
                </Card>
    
            </Div>
        );
    }

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`)}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: { md: 320 }
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Reset
                                Password</Typography>
                            <Typography variant={"body1"} mb={1} sx={{ maxWidth: 270 }}>
                                By entering your registered email address you will receive reset password link, Kindly
                                follow instruction.
                            </Typography>
                        </Div>
                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Angelytics" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    id="email-address"
                                    label="Enter Registered Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Div>
                            <Div sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <LoadingButton
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 1 }}
                                    loading={sendEmailInProgress}
                                    onClick={() => sendPasswordResetEmail()}
                                >Send</LoadingButton>
                                {sendEmailInProgress && <Typography color={"inherit"} >wait {count} sec</Typography>}
                            </Div>
                        </Div>
                        <Typography variant={"body1"} mt={'auto'}>
                            Already have an account? <Link href={"/user/login"} underline={'none'}>Login Now</Link> Or <Link
                                href={"/user/signup"} underline={'none'}>Create New</Link>
                        </Typography>
                    </Div>
                </CardContent>
            </Card>

        </Div>
    );
};

export default ResetPassword;
