import jwtAuthAxios from 'app/services/auth/jwtAuth';

export const updateService = (body) => (dispatch) => {

    return new Promise(async (resolve, reject) => {

        const response = await jwtAuthAxios.post("/update", body);


        console.log(response.data.doc)
        console.log(response.data.msg)
        return resolve(response.data.doc)

    })
};