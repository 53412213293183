import { SET_USER, SET_TOKEN } from '../../utils/constants/authTypes';

export const setUser = (user) => {
    return dispatch => {
        dispatch({ type: SET_USER, payload: user });
    }
};

export const setToken = (token) => {
    return dispatch => {
        dispatch({ type: SET_TOKEN, payload: token });
    }
}