import React, { useEffect, useState } from 'react';
// import aggregateService from '../../../../../redux/actions/aggregate';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const DomainSelector = () => {
    // Redux
    const dispatch = useDispatch();

    // Auth
    const { user } = useSelector(state => state.auth);

    // State
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(null);

    // use aggregateService to get domains
    useEffect(() => {
        // if (user?.apiKey) fetchDomains();
    }, []);

    // Aggregation service to fetch domains
    const fetchDomains = async () => {

        // Pipeline
        let params = [
            {
                $match: {
                    apiKey: user.apiKey,
                    "location.hostname": { $ne: null }, // Don't include any events that don't have a hostname
                    "location.hostname": { $ne: "angelytics-sa.github.io" }, // Exclude hostname "angelytics-sa.github.io"

                }
            },
            {
                $group: {
                    _id: "$location.hostname", // Group by hostname - this will be considered the domain
                }
            },
            {
                $sort: {
                    _id: 1
                }
            }
        ];

        let body = {
            "_model": "Event",
            params
        }

        // Get the domains
        // let response = await aggregateService(body)

        // Set the domains to state
        // setDomains(response);

    }

    // Update the selected domain in the redux store when selectedDomain changes
    useEffect(() => {
        if (selectedDomain) dispatch({ type: 'SET_DOMAIN', payload: selectedDomain })
    }, [selectedDomain])

    // console.log('window', window)
    return (
        // A select dropdown with options
        <FormControl>
            <InputLabel id="select-domain">Select A Domain</InputLabel>
            <Select sx={{ width: "300px" }} labelId="select-domain" onChange={e => setSelectedDomain(e.target.value)} value={selectedDomain}>

                {/* Provide option to view all */}
                {/* <MenuItem value={null}>All</MenuItem> */}

                {domains.map((domain, index) => {
                    return <MenuItem value={domain} key={index}>{domain._id}</MenuItem>
                })}
            </Select>
        </FormControl>

    )
}

export default DomainSelector;