import { GET_EVENTS } from "app/utils/constants/eventTypes"

const initialState = {
    data: null,
}

const eventReducer = (state = initialState, action) => {
    console.log("action.payload", action)
    switch (action.type) {
        case GET_EVENTS:
            return {
                ...state,
                data: action.payload,
            }

        default:
            return state
    }
}

export default eventReducer