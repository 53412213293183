import { CheckCircle, EditOutlined } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import { updateService } from 'app/redux/actions/crud/updateService';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const UserProfile = () => {

    // Redux
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    // State
    const [profile, setProfile] = useState({})
    const [edit, setEdit] = useState({})

    // useEffect to update profile with user
    useEffect(() => {
        if (user) {
            setProfile(user)
        }
    }, [user])

    const updateProfile = () => {
        dispatch(updateService(
            {
                "_model": "User",
                "params": {
                    "_id": user._id
                },
                "update": profile
            }
        ))

    }


    // User Profile

    if (!user) return null;

    return (
        <div>
            <h2>My Profile</h2>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Your name</p>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"

                }}>

                    {/* firstName */}
                    {/* Edit Mode */}
                    {edit.name && <TextField autoFocus label={profile.firstName} value={profile.firstName} onChange={e => setProfile({
                        ...profile,
                        firstName: e.target.value
                    })} />}

                    {/* lastName */}
                    {/* Edit Mode */}
                    {edit.name && <TextField label={profile.lastName} value={profile.lastName} onChange={e => setProfile({
                        ...profile,
                        lastName: e.target.value
                    })} />}

                    {edit.name && <CheckCircle onClick={() => {
                        updateProfile()
                        setEdit({ ...edit, name: !edit.name })
                    }} />}

                    {/* View Mode */}
                    {!edit.name && <h3>{profile.firstName} {profile.lastName}</h3>}
                    {!edit.name && <EditOutlined onClick={() => setEdit({ ...edit, name: !edit.name })} />}
                </div>
            </Box>
            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Your email address - {(profile.emailVerified ? "Verified!" : "Not Verified")}</p>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"

                }}>

                    {/* Edit Mode */}
                    {edit.email && <TextField fullWidth label={profile.email} value={profile.email} onChange={e => setProfile({
                        ...profile,
                        email: e.target.value
                    })} />}
                    {edit.email && <CheckCircle onClick={() => {
                        updateProfile()
                        setEdit({ ...edit, email: !edit.email })
                    }} />}

                    {/* View Mode */}
                    {!edit.email && <h3>{profile.email}</h3>}
                    {!edit.email && <EditOutlined onClick={() => setEdit({ ...edit, email: !edit.email })} />}
                </div>
            </Box>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Phone - {(profile.phoneVerified ? "Verified!" : "Not Verified")}</p>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"

                }}>

                    {/* Edit Mode */}
                    {edit.phone && <TextField fullWidth label={profile.phone} value={profile.phone} onChange={e => setProfile({
                        ...profile,
                        phone: e.target.value
                    })} />}
                    {edit.phone && <CheckCircle onClick={() => {
                        updateProfile()
                        setEdit({ ...edit, phone: !edit.phone })
                    }} />}

                    {/* View Mode */}
                    {!edit.phone && <h3>{profile.phone}</h3>}
                    {!edit.phone && <EditOutlined onClick={() => setEdit({ ...edit, phone: !edit.phone })} />}
                </div>
            </Box>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>2FA {(profile.is2FA ? "yes" : "no")}</p>
            </Box>


            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Profile Pic</p>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"

                }}>

                    {/* Edit Mode */}
                    {edit.profile_pic && <TextField fullWidth label={profile.profile_pic} value={profile.profile_pic} onChange={e => setProfile({
                        ...profile,
                        profile_pic: e.target.value
                    })} />}
                    {edit.profile_pic && <CheckCircle onClick={() => {
                        updateProfile()
                        setEdit({ ...edit, profile_pic: !edit.profile_pic })
                    }} />}

                    {/* View Mode */}
                    {!edit.profile_pic && <h3>{profile.profile_pic}</h3>}
                    {!edit.profile_pic && <EditOutlined onClick={() => setEdit({ ...edit, profile_pic: !edit.profile_pic })} />}
                </div>
            </Box>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Account Type</p>
                <h3>{profile.type}</h3>
            </Box>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Created</p>
                <h3>{moment(profile.createdAt).format('lll')}</h3>
            </Box>

            <Box style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                padding: "1rem 0"
            }}>
                <p>Last Update</p>
                <h3>{moment(profile.updatedAt).format('lll')}</h3>
            </Box>

        </div>
    );
};

export default UserProfile;