import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ListItem from "./ListItem";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const DataList = ({ data }) => {


    /*
    
    Example of the data array that should be passed
        [
            {
                "_id": "Name that gets displayed",
                "count": 123
            },
            {
                "_id": "String",
                "count": Number
            },
            {
                "_id": "Another Name",
                "count": 321
            }
        ]
    
    */

    return (
        <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={274}
            autoHide={true}
            hideTracksWhenNotNeeded
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ pl: 3 }}>Name</StyledTableCell>
                        <StyledTableCell sx={{ pr: 3 }} align={"right"}>Count</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((item, index) => (
                            <ListItem item={item} key={index} />
                        ))
                    }
                </TableBody>
            </Table>
        </JumboScrollbar>
    );
};
/* Todo products prop define */
export default DataList;
