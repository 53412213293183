import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PageViewsItem from "./PageViewsItem";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const EventDetailsList = ({ eventDetails }) => {

    return (
        <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={274}
            autoHide={true}
            hideTracksWhenNotNeeded
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ pl: 3 }}>Name</StyledTableCell>
                        <StyledTableCell sx={{ pr: 3 }} align={"right"}>Count</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        eventDetails.map((item, index) => (
                            <PageViewsItem item={item} key={index} />
                        ))
                    }
                </TableBody>
            </Table>
        </JumboScrollbar>
    );
};
/* Todo products prop define */
export default EventDetailsList;
